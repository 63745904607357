import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';
import throttle from 'lodash/throttle.js';

// To reduce bundle size, import only the components and icons you need
// https://github.com/warp-ds/elements?tab=readme-ov-file#import-components
// https://warp-ds.github.io/tech-docs/components/icons/
import { i18n } from '@lingui/core';
import '@warp-ds/elements/components/badge';
import '@warp-ds/elements/components/button';
import '@warp-ds/elements/components/card';
import '@warp-ds/icons/elements/shipping-16';
import { customElement } from 'lit/decorators/custom-element.js';
import { DisplayAdsPlacement, RecommendationItem, Recommendations, OikotieData } from '../../server/types.js';
import './favorite-heart.js';
import './recommendation-badge.js';
import './advertising-card.js';
import './recommendation-card.js';
import {
	getDisplayAdsPlacement,
	isItemAdvertising,
	trackViewEvent,
	shouldTriggerInfiniteScroll,
	insertDisplayAds,
} from './utils/index.js';

@customElement('frontpage-recommendations')
export class FrontpageRecommendations extends WarpElement {
	static styles = [
		...WarpElement.styles, // TODO: why doesn't pick up correct brand colours?
		css`
			.custom-label-style {
				background: linear-gradient(
					transparent,
					rgba(0, 0, 0, 0.04935) 13.71%,
					/* ... (rest of the gradient styles) */ rgba(0, 0, 0, 0.78731) 89.73%,
					rgba(0, 0, 0, 0.8)
				);
				width: 100%;
			}
			.two-lines {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}

			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.flex-row{flex-direction:row}.gap-16{gap:1.6rem}.grid-flow-row-dense{grid-auto-flow:dense}.static{position:static}.h-screen{height:100vh}.my-16{margin-top:1.6rem;margin-bottom:1.6rem}.mb-16{margin-bottom:1.6rem}.mb-4{margin-bottom:.4rem}.text-14{font-size:var(--w-font-size-s);line-height:var(--w-line-height-s)}.text-ml{font-size:var(--w-font-size-ml);line-height:var(--w-line-height-ml)}@media (width>=768px){.md\:grid-cols-3{grid-template-columns:repeat(3,minmax(0,1fr))}};
		`,
	];

	data!: Recommendations;
	newItems!: RecommendationItem[];
	url!: string;
	imgCdnUrl!: string;
	favoriteBaseUrl!: string;
	brand!: string;
	loginId!: string;
	nextPage = 0;
	canFetchMore = true;
	hasBottomReachedBeenTracked = false;
	isFetching = false;
	_displayAdsPlacement!: DisplayAdsPlacement[];
	oikotieData!: OikotieData;
	showOikotieSection = false;
	wcRef: HTMLElement | null | undefined;
	isLoading = true;

	static properties = {
		data: { type: Object, default: {}, attribute: false },
		imgCdnUrl: { type: String },
		favoriteBaseUrl: { type: String },
		newItems: { type: Object, default: [], attribute: false },
		url: { type: String },
		brand: { type: String },
		loginId: { type: String },
		canFetchMore: { type: Boolean, attribute: false, state: true },
		nextPage: { type: Number, attribute: false, default: 0 },
		_displayAdsPlacement: { type: Object, default: [], attribute: false },
		oikotieData: { type: Object },
		showOikotieSection: { type: Boolean, converter: (value: string | null) => value !== null && value !== 'false' },
	};

	get halfWayIndex() {
		return Math.ceil(this.data?.fetchMore.length / 2);
	}

	get hasOikotieSectionContent() {
		return this.showOikotieSection && this.oikotieData && this.oikotieData.cards?.length > 0;
	}

	connectedCallback() {
		super.connectedCallback();

		const locale = this.locale || 'en';
		const messages = JSON.parse(this.getAttribute('translations') || '{}');

		i18n.load(locale, messages);
		i18n.activate(locale);

		// Add infinite scroll event listener
		this.addInfiniteScroll();

		// Track bottom reach
		window.addEventListener('scroll', this.handleBottomReach);
	}

	async firstUpdated() {
		this.wcRef = this.shadowRoot?.getElementById('recommendations');
		await this.getRecommendations();
		this._displayAdsPlacement = getDisplayAdsPlacement();
		this.trackVisibleRecommendations();
	}

	async updated() {
		await this.updateComplete;
		this.trackVisibleRecommendations();
	}
	trackVisibleRecommendations() {
		const getElementForItem = (item: RecommendationItem): Element | null => {
			const id = isItemAdvertising(item) ? item.itemId.replace(/:/g, '-') : item.itemId;
			return this.shadowRoot?.getElementById(id) ?? null;
		};
		trackViewEvent(this.data, getElementForItem);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeInfiniteScroll();
		window.removeEventListener('scroll', this.handleBottomReach);
	}

	// Infinite scroll & fetch more
	addInfiniteScroll = () => {
		window.addEventListener('scroll', this.handleInfiniteScroll);
	};

	handleInfiniteScroll = throttle(() => {
		const target = this.shadowRoot?.getElementById('recommendations') || document.body;
		const containerHeight = target?.offsetHeight;
		const scrollPosition = window.innerHeight + window.scrollY;

		const { shouldFetchMore, shouldRemoveScroll } = shouldTriggerInfiniteScroll(
			scrollPosition,
			containerHeight,
			this.canFetchMore,
			this.nextPage,
			this.halfWayIndex,
		);

		if (shouldFetchMore) {
			this.fetchMore();
		}

		if (shouldRemoveScroll) {
			this.removeInfiniteScroll();
		}
	}, 200);

	removeInfiniteScroll = () => {
		window.removeEventListener('scroll', this.handleInfiniteScroll);
	};

	async fetchData(url: string) {
		const maxRetries: number = 3;
		let attempts = 0;

		while (attempts < maxRetries) {
			try {
				const response = await fetch(url);
				if (response.ok) return await response.json();
				console.warn(`Attempt ${attempts + 1}: Failed to fetch recommendations, resp:`, response);
			} catch (error) {
				console.error(`Attempt ${attempts + 1}: Error fetching data from ${url}:`, error);
			}
			attempts++;
			if (attempts < maxRetries) {
				await new Promise((resolve) => setTimeout(resolve, 1000)); // 1-second delay
			}
		}

		return null;
	}

	insertOikotieAd() {
		if (!this.data || this.data.items.length <= 4) return;
		if (this.data.items.some((item) => item.itemId === 'oikotie')) return; // Prevent duplicate insertion

		const part1 = this.data.items.slice(0, 4);
		const part2 = this.data.items.slice(4);
		const oikotieAd = { itemId: 'oikotie', type: 'oikotie' };

		this.data.items = [...part1, oikotieAd, ...part2];
	}

	insertOikotieSection() {
		const recomPart1 = this.data.items.slice(0, 6);
		const oikotieSection = { itemId: 'oikotie-section', type: 'oikotie-section' };
		const recomPart2 = this.data.items.slice(6);
		this.data.items = [...recomPart1, oikotieSection, ...recomPart2];
	}

	async getRecommendations() {
		const url = `${this.url}/api/recommendations`;
		const data = await this.fetchData(url);

		if (data) {
			this.data = data;
			this.isLoading = false;
			if (this.brand === 'TORI') {
				if (this.hasOikotieSectionContent) {
					this.insertOikotieSection();
				} else {
					this.insertOikotieAd();
				}
			}
		} else {
			this.isLoading = false;
			this.data = {
				items: [],
				fetchMore: [],
				isPersonal: false,
				hasConsent: false,
				size: 0,
				version: '',
			};
		}
	}

	async fetchMore() {
		if (this.isFetching || !this.canFetchMore) return;

		this.isFetching = true;

		const url = `${this.url}/api/fetch-more/${this.data?.fetchMore[this.nextPage]}`;
		const data = await this.fetchData(url);

		if (this._displayAdsPlacement.length === 0) {
			this._displayAdsPlacement = getDisplayAdsPlacement();
		}

		if (data) {
			if (this.nextPage + 1 > this.halfWayIndex) {
				this.addInfiniteScroll();
			}
			this.nextPage = this.nextPage + 1;
			this.canFetchMore = this.nextPage !== this.data?.fetchMore.length;
			this.newItems = data.items;
			this.data.items.push(...this.newItems);
			insertDisplayAds(this._displayAdsPlacement, this.data);
			this.isFetching = false;
		} else {
			this.isFetching = false;
			this.removeInfiniteScroll();
		}
	}

	_handleFetchMore() {
		this.fetchMore();
	}

	handleBottomReach = () => {
		const bottom = document.querySelector('frontpage-recommendations')?.shadowRoot?.getElementById('bottom');
		if (bottom) {
			const reached = bottom.getBoundingClientRect().top <= window.innerHeight;

			if (reached && !this.canFetchMore && !this.hasBottomReachedBeenTracked) {
				this.hasBottomReachedBeenTracked = true;
				fetch(`${this.url}/tracking`);
			}
		}
	};

	/** --- Internal UI logic helpers --- **/
	showHistoryLink = () => {
		return this.data?.items.length > 0 && this.brand?.toUpperCase() === 'FINN' && this.data?.isPersonal;
	};
	showFetchMoreButton = () => {
		const isHalfway = this.nextPage === this.halfWayIndex;
		const hasMoreData = Array.isArray(this.data?.fetchMore) && this.data.fetchMore.length > 0;
		return hasMoreData && this.canFetchMore && isHalfway;
	};

	recommendationsTitle = () => {
		return this.data?.isPersonal
			? i18n.t({
					id: 'frontpage.recommendations.section.title',
					message: 'Personalized recommendations',
				})
			: i18n.t({
					id: 'frontpage.popular.section.title',
					message: 'Popular ads',
				});
	};

	render() {
		if (this.isLoading) {
			return html`<div class="h-screen w-full"></div>`;
		}
		if (!this.data || this.data?.items?.length === 0) {
			return html``;
		}
		return html`
			<div id="recommendations">
				<section>
					<div class="flex flex-row items-center my-16">
						<h1 class="text-ml mb-4">${this.recommendationsTitle()}</h1>
						${this.showHistoryLink()
							? html`<a href="/anbefalinger/historikk" class="link text-14 px-8">
									${i18n.t({
										id: 'frontpage.recommendations.history.link.text',
										message: 'Why we recommend these ads?',
									})}
								</a>`
							: ''}
					</div>
					<div class="grid grid-cols-2 md:grid-cols-3 gap-16 mb-16 grid-flow-row-dense">
						${this.data?.items?.map((item) =>
							isItemAdvertising(item)
								? html`<advertising-card
										.item=${item}
										.data=${this.data}
										.url=${this.url}
										.oikotieData=${this.oikotieData}
										id=${item.itemId}
										class="${this.hasOikotieSectionContent ? 'col-span-2' : ''}"
									></advertising-card>`
								: html`<recommendation-card
										.item=${item}
										.data=${this.data}
										.imgCdnUrl=${this.imgCdnUrl}
										.favoriteBaseUrl=${this.favoriteBaseUrl}
										.loginId=${this.loginId}
										.wcRef=${this.wcRef}
										id=${item.itemId}
									></recommendation-card>`,
						)}
					</div>
					${this.showFetchMoreButton()
						? html`
								<div class="text-center mb-16">
									<w-button variant="primary" @click="${this._handleFetchMore}"
										>${i18n.t({
											id: 'frontpage.recommendations.fetch-more-button.label',
											message: 'See more recommendations',
										})}</w-button
									>
								</div>
							`
						: ''}
					<span class="hidden" id="bottom"></span>
				</section>
			</div>
		`;
	}
}
