import { html, css } from 'lit';
import { RecommendationItem, Recommendations } from '../../server/types.js';
import { trackClickEvent, isItemCMS } from './utils/index.js';
import './recommendation-badge.js';
import './favorite-heart.js';
import { i18n } from '@lingui/core';
import WarpElement from '@warp-ds/elements-core';
import { customElement } from 'lit/decorators/custom-element.js';

@customElement('recommendation-card')
export class RecommendationCard extends WarpElement {
	static styles = [
		...WarpElement.styles,
		css`
			.custom-label-style {
				background: linear-gradient(
					transparent,
					rgba(0, 0, 0, 0.04935) 13.71%,
					/* ... (rest of the gradient styles) */ rgba(0, 0, 0, 0.78731) 89.73%,
					rgba(0, 0, 0, 0.8)
				);
				width: 100%;
			}
			.two-lines {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}

			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.text-body{font-size:var(--w-font-size-m);line-height:var(--w-line-height-m)}.aspect-square{padding-bottom:100%;position:relative}.aspect-square>*{width:100%;height:100%;position:absolute;inset:0}.rounded-t-8{border-top-left-radius:8px;border-top-right-radius:8px}.hover\:no-underline:hover{text-decoration:none}.static{position:static}.hover\:s-bg-hover:hover{background-color:var(--w-s-color-background-hover)}.active\:s-bg-active:active{background-color:var(--w-s-color-background-active)}.mx-16{margin-left:1.6rem;margin-right:1.6rem}.mb-14{margin-bottom:1.4rem}.mt-6{margin-top:.6rem}.mt-8{margin-top:.8rem}.pb-10{padding-bottom:1rem}.pl-10{padding-left:1rem}.pr-16{padding-right:1.6rem}.pt-\[88px\]{padding-top:88px}.object-cover{object-fit:cover}.object-scale-down{object-fit:scale-down}.object-center{object-position:center}.text-ellipsis{text-overflow:ellipsis}@media (width>=480px){.sm\:h-auto{height:auto}};
		`,
	];

	item!: RecommendationItem;
	data!: Recommendations;
	imgCdnUrl!: string;
	favoriteBaseUrl!: string;
	loginId!: string;
	wcRef!: HTMLElement | null | undefined;

	static properties = {
		item: { type: Object },
		data: { type: Object },
		imgCdnUrl: { type: String },
		favoriteBaseUrl: { type: String },
		loginId: { type: String },
		wcRef: { type: Object },
	};

	render() {
		const href = this.item && isItemCMS(this.item) ? this.item?.itemId : `/${this.item?.itemId}`;

		return html` <w-card>
			${this.item?.image ? this.renderImage() : ''}
			<div class="text-s s-text-subtle mx-16 mt-8">
				<span>${this.item?.location?.combined}</span>
			</div>
			<div class="mx-16 mt-6 mb-14">
				<h2 class="mb-0 break-words text-body font-normal text-ellipsis two-lines">
					<a
						class="s-text hover:no-underline text-md"
						href=${href}
						@click="${() => trackClickEvent(this.item, this.data)}"
					>
						<span class="absolute inset-0" aria-hidden="true"></span>
						${this.item?.heading}
					</a>
				</h2>
				<div class="text-s s-text-subtle mt-8">
					${this.item?.content?.company_name ? html`<div>${this.item?.content?.company_name}</div>` : ''}
					${this.item?.customLabel ? html`<div>${this.item?.customLabel}</div>` : ''}
				</div>
			</div>
			${isItemCMS(this.item)
				? ''
				: html`<favorite-heart
						.item=${this.item}
						.favoriteBaseUrl=${this.favoriteBaseUrl}
						.loginId=${this.loginId}
						.wcRef=${this.wcRef}
					></favorite-heart>`}
			<recommendation-badge .item=${this.item}></recommendation-badge>
		</w-card>`;
	}

	private renderImage() {
		const isLogo = this.item?.image?.type === 'LOGO';
		const imageClass = isLogo
			? 'w-full h-full object-center object-scale-down m-auto sm:h-auto'
			: 'w-full h-full object-center object-cover m-auto';
		const imageSrc =
			this.item?.image?.isAbsolute || this.item?.image?.type === 'EXTERNAL'
				? this.item?.image?.url
				: `${this.imgCdnUrl}/${this.item?.image?.url}`;

		return html`
			<div class="aspect-square overflow-hidden border rounded-t-8 s-bg hover:s-bg-hover active:s-bg-active">
				<img
					class="${imageClass}"
					src="${imageSrc}"
					alt="${i18n.t({ id: 'frontpage.recommendations.images.alt.text', message: 'Photo from ad' })}"
				/>
				${this.item?.type?.toUpperCase() !== 'JOB'
					? html`
							<span>
								<div
									class="absolute bottom-0 font-bold s-text-inverted-static pt-[88px] pb-10 pl-10 pr-16 custom-label-style"
								>
									${this.item?.label}
								</div>
							</span>
						`
					: ''}
			</div>
		`;
	}
}
